<template>
  <div class="special">
    <template>
      <div>
        <detail ref="qianh" @getName="getNameYx" @collect="shouCang" @delCollect="delCollect" />
      </div>
    </template>

    <Nav ref="nav" :active="active" :daoXuan="daoXuan" :kaogang="kaogang" />

    <!--        <div class="tabbar" v-if="!$store.state.special.fenbu">-->
    <!--            <ul>-->
    <!--                <li @click="navClick">-->
    <!--                    <p class="p1"><van-icon name="bars" /></p>-->
    <!--                    <p class="p2">目录</p>-->
    <!--                </li>-->
    <!--                &lt;!&ndash; <li @click="shouCang"> &ndash;&gt;-->
    <!--                <li @click="shouCang" class="shouCang" :class="($refs.qianh && $refs.qianh.topicData.labels)?'on':''">-->
    <!--                    <p class="p1"><van-icon :name="($refs.qianh && $refs.qianh.topicData.labels)?'star':'star-o'" /></p>-->
    <!--                    <p class="p2">{{($refs.qianh && $refs.qianh.topicData.labels)?'已收藏':'收藏'}}</p>-->
    <!--                </li>-->
    <!--                &lt;!&ndash; <li class="tishi">-->

    <!--                </li> &ndash;&gt;-->
    <!--                <li @click="daoXuan">-->
    <!--                    <p class="p1"><van-icon name="apps-o" /></p>-->
    <!--                    <p class="p2">导学</p>-->
    <!--                </li>-->
    <!--                <li @click="kaogang">-->
    <!--                    <p class="p1"><van-icon name="apps-o" /></p>-->
    <!--                    <p class="p2">考纲</p>-->
    <!--                </li>-->
    <!--            </ul>-->
    <!--        </div>-->

    <van-image-preview v-model="showImg" :images="images" closeable>
      <!-- <template v-slot:index>第{{ index }}页</template> -->
    </van-image-preview>

    <van-popup
      v-model:show="showCheckTopic"
      title=""
      closeable
      position="bottom"
    >
      <!-- <img src="https://img01.yzcdn.cn/vant/apple-3.jpg" /> -->
      <div class="checkTopic-con">
        <ul>
          <li v-for="(item, n) in topicTotal" :key="n" @click="checkTopic(n)">
            {{ item }}
          </li>
        </ul>
      </div>
    </van-popup>

    <van-popup v-model="showShouCang">
      <div class="shouCangCon">
        <div class="close-popup" @click="showShouCang = false">
          <van-icon name="cross" />
        </div>
        <div class="top">
          <i class="iconfont icongeneral-shoucang"></i>
        </div>
        <div class="info">
          <p class="p1">给题目添加一个标签</p>
          <ul>
            <li
              :class="scListLabel.includes(item) ? 'on' : ''"
              v-for="(item, n) in scLi"
              :key="n"
              @click="addShouCang(item)"
            >
              {{ item }}
            </li>
          </ul>
          <div class="zdy" :class="zdySr ? 'on' : ''">
            <p v-if="!zdySr" @click="zdySr = !zdySr">其他，点击输入</p>
            <input
              v-else
              v-model="labelTxt"
              placeholder="请输入标签，不超过10个字"
            />
          </div>
          <div class="zdy-btn" @click="label_write">继 续</div>
        </div>
      </div>
    </van-popup>

    <div class="directory-nav" @click="navClick">
      <p>目录</p>
    </div>

    <div class="join" @click="helpDialog = true">
      <div>联系我们</div>
      <van-icon name="arrow" />
    </div>

    <van-overlay :show="helpDialog" @click="helpDialog = false" lock-scroll>
      <div class="wrapper" @click.stop>
        <div class="helpDialog">
          <div class="helpDialog__container">
            <div class="helpDialog__content">
              <img src="https://wq-ky.com/static/img/laoshi.jpeg" />

              <div class="helpDialog__content-desc">如对题目有疑问，请长按识别并添加老师微信。感谢反馈！</div>
            </div>

            <div class="helpDialog__line" />
            <div class="helpDialog__close" @click="helpDialog = false">
              关闭
            </div>
          </div>
        </div>
      </div>
    </van-overlay>
  </div>
</template>

<script>
import querystring from "querystring";
import Nav from "./nav";
import detail from "./detail";
import utils from "@/assets/js/utils";
export default {
  name: "special",
  data() {
    return {
      helpDialog: false,
      showShouCang: false,
      showPopover: false,
      zdySr: false,
      scLi: ["概念定义", "计算能力", "性质运用", "题型新颖", "综合性高", "特殊技巧"],
      scListLabel: [],
      labelTxt: "",

      showImg: false,
      images: [],

      zjTs: false,
      showDx: false,
      query: "",
      navLeftShow: false,
      active: "",
      showCheckTopic: false,

      message: "",
      showZj: false,

      zongjieList: [],
      number: ["一", "二", "三", "四", "五", "六", "七", "八", "九"],

      isAddShow: false,

      buzhou: "-1",

      infi: true,

      isStatus: false,

      // topicTotal: 0,

      tishiList: [],
      tishiShowList: [],
    };
  },
  components: {
    Nav,
    detail,
  },
  computed: {
    navInfo() {
      let navInfo = this.$store.state.special.navInfo;
      this.isStatus = false;
      if (navInfo && navInfo.childs.length > 0) {
        let infi = navInfo.infi;
        this.infi = infi;
      }
      this.$nextTick((e) => {
        this.isStatus = true;
      });
      return navInfo;
    },
    topicTotal() {
      return this.$store.state.special.topicTotal || 0;
    },
  },
  watch: {
    showPopover(newValue, oldValue) {
      this.tishiShowList = [];
      this.tishiList = [];
      if (newValue && this.tishiList.length == 0) {
        let topicData = this.$refs.qianh.topicData;
        let fenbu = topicData["分步"];
        fenbu.map((e, n) => {
          let html = this.$kx.renderToString(e["步骤"], {
            throwOnError: false,
          });
          this.tishiList.push(html);
        });
      }
    },
    "$store.state.show_pic"(val) {
      if (val) {
        this.yindao();
      }
    },
  },
  created() {
    if (this.query.is_try) {
      return false;
    }
    let show_pic = this.$store.state.show_pic;
    if (show_pic) {
      this.yindao();
    }
  },
  methods: {
    yindao() {
      // this.$router.push({path:'/yindao', query: this.$route.query});
      location.href = "/yindao?" + querystring.stringify(this.$route.query);
    },
    addShouCang(e) {
      let arr = new Set(this.scListLabel);
      if (arr.has(e)) {
        arr.delete(e);
      } else {
        arr.add(e);
      }
      this.scListLabel = Array.from(arr);
    },
    shouCang() {
      this.showShouCang = true;
      this.scListLabel = [];
    },
    label_write() {
      if (this.labelTxt) {
        this.addShouCang(this.labelTxt);
      }
      if (this.scListLabel.length === 0) {
        this.$toast("请选择收藏标签！");
        return;
      }

      let label = this.scListLabel.join(",");
      let navInfo = this.navInfo;
      let dir = navInfo.childs[0].dir;
      dir = dir.split("/");
      dir = dir[dir.length - 1];
      let param = {
        book: this.$route.query.book,
        zhuanti: dir,
        exam_idx: this.$refs.qianh.exam_idx,
        label: label,
      };
      this.$api.label_write({ param, method: "post" }).then((res) => {
        this.$toast("收藏成功！");
        this.$refs.qianh.handleUpdateLabels(this.scListLabel.join(","));
      });
      this.showShouCang = false;
    },
    tishi(n) {
      this.tishiShowList.push(n);
      this.tishiShowList = Array.from(new Set(this.tishiShowList));
    },
    checkTopic(n) {
      this.$refs.qianh.exam_idx = n;
      // this.$refs.qianh.clearDjs();
      this.$refs.qianh.init();
      // this.$refs.qianh.djs(this.navInfo);
      // this.$refs.qianh.getDir(this.navInfo);

      this.showCheckTopic = false;
    },
    showTopic() {
      this.showCheckTopic = true;
    },
    getNameYx(e, n) {
      console.log("1111Yx", e, n);
      // this.$refs.nav.navClick(e,n)
      this.$refs.nav.this_topic = e.name;
      this.$store.commit("setThis_topic", e.name);
      this.$refs.nav.checkTopic(n + 1, e, 0);
    },
    liClick(e) {
      this.message = this.message + e;
      this.zjTs = false;
    },
    navClick() {
      let is_try = this.$route.query.is_try;
      let navLeftShow = this.navLeftShow;
      navLeftShow = navLeftShow ? false : true;
      if (is_try) {
        this.$store.commit("setNav", navLeftShow);
        return false;
      }
      let topicData = this.$store.state.special.topicData;
      if (topicData["索引"] != 999) {
        this.$refs.nav.getNavList();
      }
      this.$store.commit("setNav", navLeftShow);
    },
    daoXuan(index = 0) {
      let solution_dir =
        this.$store.state.special.leftNavInfo.childs[index].childs[0]
          .solution_dir;
      solution_dir.map((e, n) => {
        this.$ajax({
          url: e,
          data: {},
          success: (res) => {
            console.log("img", res.img);
            let imgSrc = "data:image/jpeg;base64," + res.img;
            // this.images[0] = imgSrc;
            this.$set(this.images, n, imgSrc);
          },
        });
      });

      this.showImg = true;
    },
    kaogang(index) {
      console.log(this.$store.state.special.leftNavInfo.childs[index].outline);
      this.$refs.qianh.kaogangText =
        this.$store.state.special.leftNavInfo.childs[index].outline;
      this.$refs.qianh.showKg = true;
    },
    goFenbu() {
      this.$store.commit("setFenbu", true);
    },

    delCollect () {
      this.scListLabel = [];
      this.$refs.qianh.handleUpdateLabels(this.scListLabel.join(","));
    }
  }
};
</script>

<style lang="less" scoped>
.join {
  position: absolute;
  bottom: 20px;
  left: 0;
  right: 0;
  color: #415FD5;
  font-size: 13px;
  letter-spacing: 0;
  font-weight: 200;
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: center;
}

.special {
  position: relative;
  // padding: 16px 0;
  padding-bottom: 100px;
  text-align: left;
  background: #f7f8fb;
  min-height: 100vh;
  .tabbar {
    position: fixed;
    bottom: 16px;
    left: 38px;
    width: 300px;
    // height: 48px;
    background: #fff;
    z-index: 9;
    margin: 0 auto;
    border-radius: 48px;
    box-sizing: border-box;

    box-shadow: 0px 3px 12px 0px rgba(0, 0, 0, 0.2);
    ul {
      padding: 6px 0px;
      display: flex;
      font-size: 12px;
      display: flex;
      li {
        width: 24px;
        // margin-right: 46px;
        flex-grow: 4;
        text-align: center;
        line-height: 1.2;
        // &:nth-child(4n){
        //     margin-right: 0;
        // }
        .p1 {
          font-size: 18px;
          height: 18px;
          display: inline-block;
          .van-icon {
            font-size: #707693;
          }
          .iconfont {
            width: 18px;
            font-size: 18px;
          }
        }
        .p2 {
          font-size: 12px;
          color: #2a3155;
        }
        &.tishi {
          .sp {
            width: 37px;
            height: 37px;
            display: inline-block;
            background: #415fd5;
            box-shadow: 0px 0px 4px 0px rgba(42, 49, 85, 0.2);
            border-radius: 40px;
            box-sizing: border-box;
            padding: 6px;
            .iconfont {
              color: #fff;
              font-size: 20px;
            }
            &.on {
              background: #f9c200;
            }
          }
        }
        &.on {
          color: #f9c200;
          .p2 {
            color: #9498aa;
          }
        }
      }
    }
  }
  .zongjie {
    padding: 16px;
    &-add {
      width: 343px;
      height: 40px;
      background: #f9c200;
      border-radius: 24px;
      margin-top: 15px;
      font-size: 18px;
      line-height: 40px;
      text-align: center;
      color: #fff;
    }
    .title {
      height: 21px;
      font-size: 18px;
      font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
      font-weight: bold;
      color: #2a3155;
      line-height: 21px;
      padding-bottom: 16px;
    }
    .weizhi {
      height: 19px;
      font-size: 14px;
      font-family: MicrosoftYaHei;
      color: #606580;
      line-height: 19px;
    }
    &-list {
      margin-bottom: 10px;
      position: relative;
      padding: 16px 26px;
      background: #eceffa;
      border-radius: 4px;
      .icon {
        position: absolute;
        top: 12px;
        left: -8px;
        padding: 5px;
        display: inline-block;
        background: #415fd5;
        box-shadow: 1px 1px 4px 0px rgba(42, 49, 85, 0.1);
        border-radius: 14px;
        line-height: 0;
        .img {
          width: 16px;
          height: 16px;
        }
      }
      .p1 {
        font-size: 14px;
        font-family: MicrosoftYaHei;
        color: #2a3155;
        line-height: 19px;
        padding-bottom: 8px;
      }
      .p2 {
        font-size: 14px;
        font-family: MicrosoftYaHei;
        color: #9498aa;
        line-height: 19px;
      }
    }
    .p3 {
      padding-top: 23px;
      padding-bottom: 23px;
      text-align: center;
      height: 19px;
      font-size: 14px;
      font-family: MicrosoftYaHei;
      color: #bfc1cc;
      line-height: 19px;
    }
    /deep/textarea::-webkit-input-placeholder {
      color: #666;
    }
    /deep/textarea::-moz-placeholder {
      /* Mozilla Firefox 19+ */
      color: #666;
    }
    /deep/textarea:-moz-placeholder {
      /* Mozilla Firefox 4 to 18 */
      color: #666;
    }
    /deep/textarea:-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: #666;
    }
  }
  .zj-msg {
    position: relative;
    .zjTs-prop {
      position: absolute;
      top: 50px;
      left: 10px;
      background: #fff;
      padding: 16px;
      width: 285px;
      box-shadow: 0px 0px 12px 0px rgba(42, 49, 85, 0.2);
      z-index: 999;
      .p1 {
        font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
        font-weight: bold;
        color: #2a3155;
        line-height: 19px;
      }
      li {
        background: #fef8e5;
        border-radius: 2px;
        margin: 8px 4px;
        line-height: 43px;
        padding-left: 16px;
      }
    }
  }
  .checkTopic-con {
    padding: 50px 20px;
    ul {
      // text-align: center;
      display: flex;
      // justify-content: center;
      flex-wrap: wrap;
    }
    li {
      width: 17vw;
      height: 17vw;
      display: inline-block;
      background: rgb(15, 74, 201);
      color: #fff;
      margin: 10px;
      font-size: 18px;
      border-radius: 90px;
      text-align: center;
      line-height: 17vw;
    }
  }
}
.tishi-con {
  width: 264px;
  min-height: 22px;
  box-sizing: border-box;
  padding: 15px 12px;
  background: #ffffff;
  box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.1);
  .p1 {
    font-size: 12px;
    font-family: MicrosoftYaHei;
    color: #606580;
    line-height: 16px;
  }
  ul {
    margin-top: 8px;
    li {
      background: #ffffff;
      box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.1);
      box-sizing: border-box;
      padding: 8px 12px;
      font-size: 12px;
      font-family: MicrosoftYaHei;
      color: #2a3155;
      line-height: 16px;
      margin-bottom: 5px;
      &.on {
        background: url("./../../assets/img/bg_1.png");
        color: #ffffff;
        background-size: cover;
      }
    }
  }
}
.van-popup--center {
  border-radius: 16px;
  overflow: hidden;
}
.shouCangCon {
  position: relative;
  width: 327px;
  padding-bottom: 24px;
  .close-popup {
    position: absolute;
    top: 8px;
    right: 12px;
    font-size: 20px;
    color: #ffffff;
  }

  .top {
    height: 129px;
    background: url("./../../assets/img/sc_bj.png") center;
    display: flex;
    justify-content: center;
    align-items: center;
    i {
      font-size: 52px;
      color: #fff;
    }
  }
  .info {
    .p1 {
      text-align: center;
      height: 21px;
      font-size: 16px;
      font-family: MicrosoftYaHei;
      color: #606580;
      line-height: 21px;
      margin-top: 24px;
      margin-bottom: 24px;
    }
    ul {
      display: flex;
      flex-wrap: wrap;
      padding: 0 24px;
      justify-content: space-between;
      li {
        width: 132px;
        height: 36px;
        background: #ffffff;
        border-radius: 4px;
        border: 1px solid #e6e8ef;

        font-size: 14px;
        font-family: MicrosoftYaHei;
        color: #2a3155;
        line-height: 36px;
        text-align: center;
        margin-bottom: 12px;
        &.on {
          border: 1px solid #415fd5;
        }
      }
    }
    .zdy {
      width: 279px;
      margin: 0 auto;
      height: 36px;
      background: #ffffff;
      border-radius: 4px;
      border: 1px solid #e6e8ef;
      text-align: center;
      line-height: 36px;
      color: #bfc1cc;
      margin-bottom: 16px;
      &.on {
        border: 1px solid #415fd5;
      }
      input {
        border: none;
        width: 98%;
        padding-left: 10px;
        color: #000;
        box-sizing: border-box;
      }
      &-btn {
        width: 279px;
        height: 40px;
        background: #415fd5;
        border-radius: 24px;
        line-height: 40px;
        font-size: 18px;
        font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
        font-weight: bold;
        text-align: center;
        color: #fff;
        margin: 0 auto;
      }
    }
  }
}

.directory-nav {
  position: fixed;
  width: 56px;
  height: 56px;
  border-radius: 50%;
  box-shadow: 0px 2px 12px rgba(42, 49, 85, 0.15);
  writing-mode: tb-rl;
  left: -24px;
  bottom: 153px;
  background: white;

  > p {
    text-align: center;
    letter-spacing: 6px;
    padding: 6px 8px 0 0;
    color: #4461ce;
    font-weight: 500;
  }
}

.helpDialog {
  margin-top: 100px;
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;

  &__container {
    width: 320px;
    background: #ffffff;
    border-radius: 12px;
  }

  &__content {
    padding: 24px;

    > image {
      width: 272px;
      height: 272px;
      background: #e5e5e5;
      border-radius: 1px;
    }

    &-desc {
      height: 24px;
      font-family: PingFangSC-Regular;
      font-size: 17px;
      color: #7d8293;
      letter-spacing: 0;
      text-align: center;
      line-height: 24px;
      font-weight: 400;
      margin: 16px 0 32px;
    }
  }

  &__line {
    height: 1px;
    background: #e5e5e5;
  }

  &__close {
    height: 55px;
    line-height: 55px;
    text-align: center;
    font-family: PingFangSC-Medium;
    font-size: 17px;
    color: #5377ff;
    letter-spacing: 2px;
    font-weight: 500;
  }
}
</style>
